
const routes = [
  {
    path: '/',
    component: () => import('@/layouts/HomeLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/pages/Home/Index.vue'),
        meta: { name_ko: 'Home' }
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      // {
      //   path: 'prematches/:sport?',
      //   name: 'prematches',
      //   component: () => import('@/pages/Prematches/Index.vue'),
      //   meta: { name_ko: '국내형스포츠' }
      // },
      // {
      //   path: 'pregames/:sport?',
      //   name: 'pregames',
      //   component: () => import('@/pages/Pregames/Index.vue'),
      //   meta: { name_ko: '스포츠' }
      // },
      // {
      //   path: 'lives/:sport?',
      //   name: 'lives',
      //   component: () => import('@/pages/Lives/Index.vue'),
      //   meta: { name_ko: '라이브' }
      // },
      {
        path: 'slots/:id/games',
        name: 'slots.games',
        component: () => import('@/pages/Slots/Games.vue'),
        meta: { name_ko: '슬롯' }
      },
      {
        path: 'slots/:id/games/:code',
        name: 'slots.play',
        component: () => import('@/pages/Casinos/Play.vue'),
        meta: { name_ko: '슬롯' }
      },
      {
        path: 'mini-games/:provider/:game',
        name: 'mini-games.show',
        component: () => import('@/pages/MiniGames/Show.vue'),
        meta: { name_ko: '미니게임' }
      },
      // {
      //   path: 'virtual-games/:sport/:league',
      //   name: 'virtual-games.show',
      //   component: () => import('@/pages/VirtualGames/Show.vue'),
      //   meta: { name_ko: '가상게임' }
      // },
      {
        path: 'deposits',
        name: 'deposits',
        component: () => import('@/pages/Deposits/Index.vue'),
        meta: { name_ko: '충전신청' }
      },
      {
        path: 'withdrawals',
        name: 'withdrawals',
        component: () => import('@/pages/Withdrawals/Index.vue'),
        meta: { name_ko: '환전신청' }
      },
      {
        path: 'questions',
        name: 'questions',
        component: () => import('@/pages/Questions/Index.vue'),
        meta: { name_ko: '고객센터' }
      },
      {
        path: 'questions/create',
        name: 'questions.create',
        component: () => import('@/pages/Questions/Create.vue'),
        meta: { name_ko: '고객센터 - 문의하기' }
      },
      {
        path: 'messages',
        name: 'messages',
        component: () => import('@/pages/Messages/Index.vue'),
        meta: { name_ko: '쪽지함' }
      },
      {
        path: 'bets/:category',
        name: 'bets',
        component: () => import('@/pages/Bets/Index.vue'),
        meta: { name_ko: '배팅내역' }
      },
      {
        path: 'attendances',
        name: 'attendances',
        component: () => import('@/pages/Attendances/Index.vue'),
        meta: { name_ko: '출석체크' }
      },
      {
        path: 'announcements',
        name: 'announcements',
        component: () => import('@/pages/Announcements/Index.vue'),
        meta: { name_ko: '공지사항' }
      },
      {
        path: 'announcements/:announcement',
        name: 'announcements.show',
        component: () => import('@/pages/Announcements/Show.vue'),
        meta: { name_ko: '공지사항' }
      },
      {
        path: 'events',
        name: 'events',
        component: () => import('@/pages/Events/Index.vue'),
        meta: { name_ko: '이벤트' }
      },
      {
        path: 'events/:event',
        name: 'events.show',
        component: () => import('@/pages/Events/Show.vue'),
        meta: { name_ko: '이벤트' }
      },
      {
        path: 'consumables',
        name: 'consumables',
        component: () => import('@/pages/Consumables/Index.vue'),
        meta: { name_ko: '아이템' }
      },
      {
        path: 'threads',
        name: 'threads',
        component: () => import('@/pages/Threads/Index.vue'),
        meta: { name_ko: '게시판' }
      },
      {
        path: 'threads/create',
        name: 'threads.create',
        component: () => import('@/pages/Threads/Create.vue'),
        meta: { name_ko: '게시판 - 글쓰기' }
      },
      {
        path: 'threads/:thread',
        name: 'threads.show',
        component: () => import('@/pages/Threads/Show.vue'),
        meta: { name_ko: '게시판' }
      },
      {
        path: 'exchanges',
        name: 'exchanges',
        meta: { name_ko: '포인트전환' }
      },
      {
        path: 'coupons',
        name: 'coupons',
        meta: { name_ko: '쿠폰' }
      },
      {
        path: 'lottery/wheel',
        name: 'lottery.wheel',
        component: () => import('@/pages/Lottery/Wheel.vue'),
        meta: { name_ko: '보너스룰렛' }
      },
      {
        path: 'duotone-balls',
        name: 'duotone-balls',
        component: () => import('@/pages/DuotoneBalls/Index.vue'),
        meta: { name_ko: '로또6/45' }
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/GameLayout.vue'),
    children: [
      {
        path: 'mini-games',
        name: 'mini-games',
        component: () => import('@/pages/MiniGames/Index.vue'),
      },
      // {
      //   path: 'virtual-games',
      //   name: 'virtual-games',
      //   component: () => import('@/pages/VirtualGames/Index.vue'),
      // },
      {
        path: 'casinos',
        name: 'casinos',
        component: () => import('@/pages/Casinos/Index.vue'),
        meta: { name_ko: '카지노' }
      },
      {
        path: 'casinos/:id/games/:code',
        name: 'casinos.play',
        component: () => import('@/pages/Casinos/Play.vue'),
        meta: { name_ko: '카지노' }
      },
      {
        path: 'casinos/:id/games/:code',
        name: 'pragmatic',
        component: () => import('@/pages/Casinos/Play.vue'),
        meta: { name_ko: '그래프게임' }
      },
      {
        path: 'slots',
        name: 'slots',
        component: () => import('@/pages/Slots/Index.vue'),
        meta: { name_ko: '슬롯' }
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '/registration/:code?',
        name: 'registration',
        component: () => import('@/pages/Auth/Registration.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/ErrorLayout.vue'),
    children: [
      {
        path: 'error',
        name: 'error',
        component: () => import('@/pages/Errors/Index.vue')
      },
    ]
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/Errors/404.vue')
  }
]

export default routes
